import { NavLink, useLocation } from "react-router-dom";
import { publicUrlFor } from "../../../globals/constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function Header() {
  const [isActive, setIsActive] = useState(false);

  function toggleNavClass() {
    setIsActive(!isActive);
  }

  function closeMenu() {
    setIsActive(false);
  }

  const location = useLocation();
  const { t } = useTranslation();

  return (
    <header
      className={
        "site-header header-style-3 mobile-sider-drawer-menu " +
        (isActive ? "active" : "")
      }
    >
      <div className="sticky-header main-bar-wraper  navbar-expand-lg">
        <div className="main-bar">
          <div className="container-fluid clearfix">
            <div className="logo-header">
              <div className="logo-header-inner logo-header-one">
                <NavLink to="/" onClick={closeMenu}>
                  <img
                    src={publicUrlFor("/assets/images/aduanapp-logo-light.svg")}
                    alt="#"
                  />
                </NavLink>
              </div>
            </div>

            {/* NAV Toggle Button */}
            <button
              id="mobile-side-drawer"
              data-target=".header-nav"
              data-toggle="collapse"
              className="navbar-toggler collapsed"
              onClick={toggleNavClass}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar icon-bar-first"></span>
              <span className="icon-bar icon-bar-two"></span>
              <span className="icon-bar icon-bar-three"></span>
            </button>

            {/* MAIN Vav */}
            <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
              {location.pathname === "/" ? (
                <ul className=" nav navbar-nav">
                  <li className="has-child">
                    <NavLink
                      to="#platform"
                      onClick={e => {
                        e.preventDefault();
                        document
                          .getElementById("platform")
                          ?.scrollIntoView({ behavior: "smooth" });
                        closeMenu();
                      }}
                    >
                      {t("platform")}
                    </NavLink>
                  </li>

                  <li className="has-child">
                    <NavLink
                      to="#services"
                      onClick={e => {
                        e.preventDefault();
                        document
                          .getElementById("services")
                          ?.scrollIntoView({ behavior: "smooth" });
                        closeMenu();
                      }}
                    >
                      {t("services")}
                    </NavLink>
                  </li>

                  <li className="has-child">
                    <NavLink to="https://app.aduanapp.com" onClick={closeMenu}>
                      {t("classifier")}
                    </NavLink>
                  </li>

                  <li className="has-child">
                    <NavLink
                      to="#contact"
                      onClick={e => {
                        e.preventDefault();
                        document
                          .getElementById("contact")
                          .scrollIntoView({ behavior: "smooth" });
                        closeMenu();
                      }}
                    >
                      {t("contact")}
                    </NavLink>
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </div>

            {/* Header Right Section */}
            <div className="extra-nav header-2-nav">
              <div className="extra-cell">
                <div className="header-nav-call-section">
                  <div className="detail">
                    <span className="title">{t("phoneCountry")}</span>
                    <span>
                      <a href="tel:525638363290" onClick={closeMenu}>
                        {t("phoneNumber")}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* SITE Search */}
        <div id="search">
          <span className="close"></span>
          <form
            role="search"
            id="searchform"
            action="/search"
            method="get"
            className="radius-xl"
          >
            <input
              className="form-control"
              name="q"
              type="search"
              placeholder="Type to search"
            />
            <span className="input-group-append">
              <button type="button" className="search-btn">
                <i className="fa fa-paper-plane"></i>
              </button>
            </span>
          </form>
        </div>
      </div>
    </header>
  );
}

export default Header;
