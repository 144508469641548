import { NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../../globals/constants";
import { useTranslation } from "react-i18next";

export default function SectionWhyChooseUs2({ _data }) {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="section-full p-t120 p-b90 site-bg-white tw-why-choose-area2"
        style={{
          backgroundImage: `url(${publicUrlFor(
            "/assets/images/background/bg-6.jpg"
          )})`,
        }}
        id="platform"
      >
        <div className="tw-why-choose-area-top2">
          <div className="container">
            <div className="tw-why-choose-section2">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="tw-why-choose-right">
                    <div className="tw-why-choose-media1 shine-effect">
                      <div className="shine-box">
                        <img
                          src={publicUrlFor("/assets/images/app-sample.png")}
                          alt="#"
                        />
                      </div>
                    </div>
                    <div className="tw-why-choose-tag slide-top shine-effect">
                      <div className="tag-box">
                        <h2>{_data.branches}</h2>
                        <h3>{t("faster")}</h3>
                        <span>{_data.since}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="tw-why-choose-left">
                    {/* TITLE START*/}
                    <div className="section-head left wt-small-separator-outer">
                      <div className="wt-small-separator site-text-primary">
                        <div>{t("platform")}</div>
                      </div>
                      <h2 className="wt-title">{t("platformSubtitle")}</h2>
                    </div>
                    {/* TITLE END*/}
                    <strong>{t("platformDescription")}</strong>
                    <ul className="description-list">
                      <li>
                        <i className="fa fa-angle-right" />
                        {t("platformBenefit1")}
                      </li>
                      <li>
                        <i className="fa fa-angle-right" />
                        {t("platformBenefit2")}
                      </li>
                      <li>
                        <i className="fa fa-angle-right" />
                        {t("platformBenefit3")}
                      </li>
                    </ul>
                    <div className="tw-why-choose-left-bottom">
                      <NavLink
                        to="https://app.aduanapp.com?w=t"
                        className="btn-half site-button"
                      >
                        <span>{t("tryForFree")}</span>
                        <em />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
