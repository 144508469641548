import SectionSlider1 from "../../sections/home/index/section-slider1";
import SectionEstimation1 from "../../sections/home/index/section-estimation1";
import SectionWhyChooseUs2 from "../../sections/home/index2/section-why-choose-us2";
import { data } from "../../../globals/data/home1";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";

function Home1Page() {
  useEffect(() => {
    loadScript("/assets/js/custom.js", true);
  });

  return (
    <>
      <SectionSlider1 />
      <SectionWhyChooseUs2 _data={data.whychoosus} />
      <SectionEstimation1 _data={data.estimation} clientData={data.clients} />
    </>
  );
}

export default Home1Page;
