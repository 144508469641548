import { NavLink } from "react-router-dom";
import SectionClients1 from "../index2/section-clients1";
import { useTranslation } from "react-i18next";

export default function SectionEstimation1({ _data, clientData }) {
  const { t } = useTranslation();
  return (
    <div
      className="section-full site-bg-white  tw-estimation-area"
      id="services"
    >
      <div
        className="tw-estimation-section bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${_data.background})` }}
      >
        {/* <div className="container">
          <div className="row">
            {_data.solutions.map((_object, index) => (
              <div key={index} className="col-xl-4 col-lg-4 col-md-6">
                <div className="tw-est-section-block">
                  <div className="tw-est-section-block-content">
                    <span className="tw-est-section-number">
                      {_object.serial}
                    </span>
                    <h3 className="tw-title">{_object.title}</h3>
                    <p>{_object.subtitle}</p>
                    <NavLink to="/about-us" className="site-button-2-outline">
                      <i className="fa fa-angle-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
        <div className="absolute inset-0 bg-black opacity-90"></div>
        <div className="container relative z-10">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="tw-est-section-block">
                <div className="tw-est-section-block-content">
                  <span className="tw-est-section-number">01</span>
                  <h3 className="tw-title text-white">{t("service1Title")}</h3>
                  <p className="text-white">{t("service1Description")}</p>
                  <NavLink
                    to={"https://app.aduanapp.com?w=t"}
                    className="site-button-2-outline"
                  >
                    <i className="fa fa-angle-right"></i>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="tw-est-section-block">
                <div className="tw-est-section-block-content">
                  <span className="tw-est-section-number">02</span>
                  <h3 className="tw-title text-white">{t("service2Title")}</h3>
                  <p className="text-white">{t("service2Description")}</p>
                  <NavLink
                    to={"https://app.aduanapp.com?w=t"}
                    className="site-button-2-outline"
                  >
                    <i className="fa fa-angle-right"></i>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="tw-est-section-block">
                <div className="tw-est-section-block-content">
                  <span className="tw-est-section-number">03</span>
                  <h3 className="tw-title text-white">{t("service3Title")}</h3>
                  <p className="text-white">{t("service3Description")}</p>
                  <NavLink
                    to={"https://app.aduanapp.com/lb"}
                    className="site-button-2-outline"
                  >
                    <i className="fa fa-angle-right"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SectionClients1 _data={clientData} />
      <div className="container p-t60 p-b120 p-l30 p-r30" id="contact">
        <div className="wt-separator-two-part">
          <div className="row wt-separator-two-part-row">
            <div className="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-left">
              {/* <!-- TITLE START--> */}
              <div className="section-head left wt-small-separator-outer">
                <div className="wt-small-separator site-text-primary">
                  <div>{t("team")}</div>
                </div>
                <h2 className="wt-title">{t("teamTitle")}</h2>
                <p className="section-head-text">{t("teamSubtitle")}</p>
              </div>
              {/* <!-- TITLE END--> */}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right text-right">
              <NavLink
                to="mailto:notificaciones@aduanapp.mx"
                className="btn-half site-button"
              >
                <span>{t("contactUs")}</span>
                <em></em>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
