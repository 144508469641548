import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    supportedLngs: ["es", "en"],
    load: "languageOnly", // This will only load 'es' or 'en', not 'es-ES' or 'en-US'
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      lookupQuerystring: "lng",
      lookupCookie: "i18nextLng",
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage", "cookie"],
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
  });

// After initialization, if no language is detected, detect from IP or set it to English
if (!i18n.language || i18n.language === "cimode") {
  async function detectLanguageFromLocation() {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();

      const countryToLanguage = {
        MX: "es", // Mexico
        US: "en", // United States
      };

      i18n.changeLanguage(countryToLanguage[data.country_code] || "en");
    } catch (error) {
      i18n.changeLanguage("en"); // Default to English
    }
  }

  const queryParams = new URLSearchParams(window.location.search);
  const urlLanguageParam = queryParams.get("lng");
  if (urlLanguageParam === "es" || urlLanguageParam === "en") {
    i18n.changeLanguage(urlLanguageParam);
  } else {
    detectLanguageFromLocation();
  }
}

export default i18n;
