import { NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../globals/constants";
import { useTranslation } from "react-i18next";

export default function Footer2() {
  const {t} = useTranslation()
  return (
    <>
      <footer className="footer-light">
        {/* NEWS LETTER SECTION END */}
        <div className="ftr-bg">
          {/* FOOTER BLOCKES START */}
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_about">
                    <div className="logo-footer clearfix">
                      <NavLink to="/">
                        <img
                          src={publicUrlFor(
                            "/assets/images/aduanapp-logo-dark.svg"
                          )}
                          alt="#"
                        />
                      </NavLink>
                    </div>
                    <p style={{ fontSize: "16px"}}>
                      {t("footerDescription")}
                    </p>
                    <ul class="social-icons">
                      <li>
                        <a
                          href="https://www.linkedin.com/company/aduanapp"
                          class="fa fa-linkedin"
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Quick Links</h3>
                    <ul>
                      <li>
                        <NavLink to="/terminos">{t("termsAndConditions")}</NavLink>
                      </li>
                      <li>
                        <NavLink to="/privacidad">
                          {t("privacyPolicy")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/legal">
                          {t("legal")}
                        </NavLink>
                      </li>
                      {/* <li><NavLink to="/blogs/grid">Blog</NavLink></li>
                                            <li><NavLink to="/faq">FAQ</NavLink></li>
                                            <li><NavLink to="/contact">Contact Us</NavLink></li> */}
                    </ul>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-6">
                                    <div className="widget widget_services ftr-list-center">
                                        <h3 className="widget-title">Services</h3>
                                        <ul>
                                            <li><NavLink to="/services/detail">Warehouse</NavLink></li>
                                            <li><NavLink to="/services/detail">Air Freight</NavLink></li>
                                            <li><NavLink to="/services/detail">Ocean Freight</NavLink></li>
                                            <li><NavLink to="/services/detail">Road Freight</NavLink></li>
                                            <li><NavLink to="/services/detail">Packaging</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="widget widget_services ftr-list-center">
                                        <h3 className="widget-title">Community</h3>
                                        <ul>
                                            <li><NavLink to="/about-us">Business Consulting</NavLink></li>
                                            <li><NavLink to="/about-us">Testimonials</NavLink></li>
                                            <li><NavLink to="/about-us">Track Your Shipment</NavLink></li>
                                            <li><NavLink to="/about-us">Privacy Policy</NavLink></li>
                                            <li><NavLink to="/about-us">Terms & Condition</NavLink></li>
                                        </ul>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}
          {/* <div className="footer-bottom">
                        <div className="container">
                            <div className="footer-bottom-info">
                                <div className="footer-copy-right">
                                    <span className="copyrights-text">Copyright © 2023 by thewebmax All Rights Reserved.</span>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </footer>
    </>
  );
}
