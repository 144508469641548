import { Route, Routes } from "react-router-dom";

import Home1Page from "../app/components/home/index";
// import Home2Page from "../app/components/home/index2";
// import Home3Page from "../app/components/home/index3";
// import Home4Page from "../app/components/home/index4";
// import AboutUsPage from "../app/components/misc/about-us";
// import ContactPage from "../app/components/misc/contact-us";
// import FaqPage from "../app/components/misc/faq";
// import Error404Page from "../app/components/misc/error404";
// import BlogGridPage from "../app/components/blogs/blog-grid";
// import BlogsDetailPage from "../app/components/blogs/blog-detail";
// import Services1Page from "../app/components/services/services1";
// import Services2Page from "../app/components/services/services2";
// import ServicesDetailPage from "../app/components/services/services-detail";
import AccordionPage from "../app/components/misc/accordion-page";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home1Page />} />
      <Route path="/terminos" element={<AccordionPage type="terms" />} />
      <Route path="/privacidad" element={<AccordionPage type="privacy" />} />
      <Route path="/legal" element={<AccordionPage type="legal" />} />
      <Route path="*" element={<Home1Page />} />
    </Routes>
  );
}

export default AppRoutes;
