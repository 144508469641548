import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { PostHogProvider } from "posthog-js/react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const options = {
  api_host: "https://us.i.posthog.com",
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey="phc_xgncAmwuhvKUAJUCxIAtVdcZiw0auhQdgaa8G13cTya"
      options={options}
    >
      <BrowserRouter basename="/">
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </PostHogProvider>
  </React.StrictMode>
);
