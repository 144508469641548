import { NavLink } from "react-router-dom";
import { loadScript, publicUrlFor } from "../../../../globals/constants";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function SectionSlider1() {
  useEffect(() => {
    loadScript("/assets/js/slider-home1.js", true);
    loadScript("/assets/js/bg-image-move.js", true);
  });

  const { t } = useTranslation();

  return (
    <div className="twm-slider1-wrap">
      {/* Swiper */}
      <div className="swiper twm-slider">
        <div className="swiper-wrapper">
          {/*Slide One */}
          {/* <div className="swiper-slide">
            <div className="h-banner-wrap">
              <div className="h-banner-left">
                <div className="h-bnr-top">
                  <h2>
                    <span className="title-outline">ADUANAS</span>
                    FACILES
                  </h2>
                  <p>
                    Evite miles de dolares en gastos, y dolorosos retrasos en
                    aduanas.
                  </p>
                  <NavLink to="/services/services1" className="h-bnr-btn">
                    Empezar
                  </NavLink>
                </div>
              </div>
              <div className="h-banner-right">
                <div className="h-bnr-r-inner">
                  <div className="h-bnr-media">
                    <img
                      src={require("../../../../assets/plane.png")}
                      alt="#"
                      className="slide-top"
                    />
                  </div>
                  <div
                    className="h-bnr-animation bg-cover bg-center bg-image-moving"
                    style={{
                      backgroundImage: `url(${publicUrlFor(
                        "/assets/images/main-slider/slider1/bg1.jpg"
                      )})`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="cross-line-box left up-down">
                <img
                  src={publicUrlFor(
                    "/assets/images/main-slider/slider1/cross-line-box.png"
                  )}
                  alt="#"
                />
              </div>
              <div className="cross-line-box right up-down">
                <img
                  src={publicUrlFor(
                    "/assets/images/main-slider/slider1/cross-line-box.png"
                  )}
                  alt="#"
                />
              </div>
              <div className="circle-left-top zoon-in-out"></div>
            </div>
          </div> */}

          {/*Slide Two */}
          {/* <div className="swiper-slide">
            <div className="h-banner-wrap">
              <div className="h-banner-left">
                <div className="h-bnr-top">
                  <h2>
                    <span className="title-outline">ADUANAS</span>
                    FLEXIBLES
                  </h2>
                  <p>
                    Trabajamos con todos los puntos de entrada - terrestres, aereos, y maritimos.
                  </p>
                  <NavLink to="/services/services1" className="h-bnr-btn">
                    View Services
                  </NavLink>
                </div>
              </div>
              <div className="h-banner-right">
                <div className="h-bnr-r-inner">
                  <div className="h-bnr-media">
                    <img
                      src={require("../../../../assets/truck.png")}
                      alt="#"
                      className="slide-top"
                    />
                  </div>
                  <div
                    className="h-bnr-animation bg-cover bg-center bg-image-moving"
                    style={{
                      backgroundImage: `url(${publicUrlFor(
                        "/assets/images/main-slider/slider1/bg2.jpg"
                      )})`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="cross-line-box left up-down">
                <img
                  src={publicUrlFor(
                    "/assets/images/main-slider/slider1/cross-line-box.png"
                  )}
                  alt="#"
                />
              </div>
              <div className="cross-line-box right up-down">
                <img
                  src={publicUrlFor(
                    "/assets/images/main-slider/slider1/cross-line-box.png"
                  )}
                  alt="#"
                />
              </div>
              <div className="circle-left-top zoon-in-out"></div>
            </div>
          </div> */}

          {/*Slide Three */}
          <div className="swiper-slide">
            <div className="h-banner-wrap">
              <div className="h-banner-left">
                <div className="h-bnr-top">
                  <h1>{t("homeBanner")}</h1>
                  <p>{t("homeSubHeader")}</p>
                  <NavLink to="https://app.aduanapp.com" className="h-bnr-btn">
                  {t("homeBannerButton")}
                  </NavLink>
                </div>
              </div>
              <div className="h-banner-right">
                <div className="h-bnr-r-inner">
                  <div className="h-bnr-media">
                    <img
                      src={require("../../../../assets/ship.png")}
                      alt="#"
                      className="slide-top"
                    />
                  </div>
                  <div
                    className="h-bnr-animation bg-cover bg-center bg-image-moving"
                    style={{
                      backgroundImage: `url(${publicUrlFor(
                        "/assets/images/main-slider/slider1/sea5.png"
                      )})`,
                    }}
                  ></div>
                </div>
              </div>
              {/* <div className="cross-line-box left up-down">
                <img
                  src={publicUrlFor(
                    "/assets/images/main-slider/slider1/cross-line-box.png"
                  )}
                  alt="#"
                />
              </div> */}
              <div className="cross-line-box right up-down">
                <img
                  src={publicUrlFor(
                    "/assets/images/main-slider/slider1/cross-line-box.png"
                  )}
                  alt="#"
                />
              </div>
              <div className="circle-left-top zoon-in-out"></div>
            </div>
          </div>
        </div>
        {/* <div className="swiper-pagination"></div> */}
      </div>
    </div>
  );
}
